var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "mt-5" },
            [
              _c(
                "b-col",
                {
                  attrs: {
                    lg: "8",
                    md: "6",
                    sm: "12",
                    order: "2",
                    "order-md": "1",
                  },
                },
                [
                  _c(
                    "b-card",
                    [
                      _c("p", { staticClass: "lead" }, [
                        _vm._v(" Welcome to PMGateway Online "),
                      ]),
                      _c("p", [
                        _vm._v(
                          " Log in to view and download from your " +
                            _vm._s(_vm.$t("ranger_analysers")) +
                            " worldwide. "
                        ),
                      ]),
                      _c("b-img", {
                        attrs: { src: "/img/marketing01.png", fluid: "" },
                      }),
                      _c(
                        "p",
                        [
                          _vm._v(" Support:"),
                          _c("br"),
                          _c("ul", [
                            _c(
                              "li",
                              [
                                _c(
                                  "b-link",
                                  {
                                    attrs: {
                                      href: "mailto:support@synergy-mi.com",
                                    },
                                  },
                                  [_vm._v(" support@synergy-mi.com ")]
                                ),
                                _vm._v(" (USA) "),
                              ],
                              1
                            ),
                            _c(
                              "li",
                              [
                                _c(
                                  "b-link",
                                  {
                                    attrs: {
                                      href: "mailto:support@outramresearch.co.uk",
                                    },
                                  },
                                  [_vm._v(" support@outramresearch.co.uk ")]
                                ),
                                _vm._v(" (UK and Rest of World) "),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" Visit "),
                          _c(
                            "b-link",
                            {
                              attrs: {
                                href: "https://www.outramresearch.co.uk/contact-us/",
                              },
                            },
                            [
                              _vm._v(
                                " https://www.outramresearch.co.uk/contact-us/ "
                              ),
                            ]
                          ),
                          _vm._v(" for more contact details. "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-2",
                  attrs: {
                    lg: "4",
                    md: "6",
                    sm: "12",
                    order: "1",
                    "order-md": "2",
                  },
                },
                [_c("b-card", [_c("login-form")], 1)],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }