var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        {
          attrs: {
            variant: "warning",
            show: !!this.$route.params.session_expired,
          },
        },
        [_vm._v(" Your session expired, please log in again to continue ")]
      ),
      _c(
        "b-alert",
        {
          attrs: {
            variant: "warning",
            show: !!this.$route.params.not_logged_in,
          },
        },
        [_vm._v(" Please log in to continue ")]
      ),
      _c(
        "b-alert",
        { attrs: { variant: "danger", show: !!this.login_data.status } },
        [_vm._v(" " + _vm._s(this.login_data.status) + " ")]
      ),
      _c(
        "b-form",
        { on: { submit: _vm.login } },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Email address" } },
            [
              _c("b-input", {
                attrs: { type: "email" },
                model: {
                  value: _vm.login_data.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.login_data, "email", $$v)
                  },
                  expression: "login_data.email",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Password" } },
            [
              _c("b-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.login_data.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.login_data, "password", $$v)
                  },
                  expression: "login_data.password",
                },
              }),
            ],
            1
          ),
          _c(
            "b-checkbox",
            {
              model: {
                value: _vm.login_data.rememberMe,
                callback: function ($$v) {
                  _vm.$set(_vm.login_data, "rememberMe", $$v)
                },
                expression: "login_data.rememberMe",
              },
            },
            [_vm._v(" Remember Me ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-1 mb-2",
              attrs: { variant: "primary", type: "submit", block: "" },
            },
            [_vm._v("Login")]
          ),
          _c(
            "b-link",
            {
              on: {
                click: function ($event) {
                  return _vm.$bvModal.show("reset_password")
                },
              },
            },
            [_vm._v("Forgot your password?")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "reset_password",
            title: "Password Reset",
            busy: _vm.reset_data.status === "DONE",
          },
          on: { ok: _vm.resetPassword },
        },
        [
          _c("p", [
            _vm._v(
              " Enter your email address below. If you have an account with us, you'll receive a password reset link by email. "
            ),
          ]),
          _c(
            "b-alert",
            {
              attrs: {
                variant: "success",
                show: _vm.reset_data.status === "DONE",
              },
            },
            [_vm._v(" Please check your email for a reset token. ")]
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Email" } },
            [
              _c("b-input", {
                attrs: {
                  type: "email",
                  state: _vm.reset_data.validation,
                  disabled: _vm.reset_data.status === "DONE",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.resetPassword.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.reset_data.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.reset_data, "email", $$v)
                  },
                  expression: "reset_data.email",
                },
              }),
              _c("b-form-invalid-feedback", [
                _vm._v("Please enter a valid email address"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }